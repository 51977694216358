import { Component, Vue, Prop } from 'vue-property-decorator'
import FormDependent from '@/components/FormCustomer/FormDependents/index.vue'
import FormCustomerModel from '@/Models/formCustomer'
import http from '@/services/http'
@Component({
  components: {
    FormDependent,
  },
})
export default class FormDependentView extends Vue {
  alreadyForm = false

  isAction = 'add'
  loading = false
  delete_modal_active = false
  baseUrl = ''

  mounted() {
    this.baseUrl = process.env.VUE_APP_API_URL
  }
}
